import React from "react";
import { graphql } from "gatsby";
import Layout from "./components/Layout";
import { Row, Col } from "react-bootstrap";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

export default function Home({ data }) {
  return (
    <Layout>

      <Row id="banner" className="min-100 bg-taieri relative pt-5"
      style={{backgroundPosition: '10% 90%', backgroundSize: '700%' }}>
        <Col xl={12} className="align-left-bottom p-5 mt-5">
          <h1 className="site-title">
              Hi, I'm Andrew. <br/>I'm a <span class
              ="text-light text-shadow">front-end developer,</span> <span class="text-light text-shadow">visual designer,</span> and <span class
              ="text-light text-shadow">writer.</span> I strive to make the web better and more ethical through design, code, and positive intention.
              {/* I create beautiful web experiences that inspire engagement. */}
          </h1>
        </Col>
        <Col className="offset-md-4"></Col>
      </Row>

      {/* about */}
      <Row className="relative min-100">
        <Fade left cascade duration={1500}>
          <ul className="list-unstyled m-0 p-0 absolute z-0 left w-100 h-100">
            <li className="w-100 h-25 bg-black"></li>
            <li className="w-100 h-25 bg-black"></li>
            <li className="w-100 h-25 bg-black"></li>
            <li className="w-100 h-25 bg-black"></li>
          </ul>
        </Fade>
        <Fade delay={1000}>
        <div className="absolute z-0 h-100 w-100 bg-taieri" style={{backgroundSize: '50%'}}></div>
        </Fade>
          <Col md={{ span: 7, offset: 5 }} className="align-left min-50 rounded-lg py-5">
            <Fade bottom>
            <div className="list-unstyled p-5 m-3 shadow-lg rounded-lg bg-blue text-light bg-taieri" style={{backgroundPosition: '100% 12%', backgroundSize: '2000%'}}>
              <Zoom bottom>
                <h1 className="site-title">about me.</h1>
              </Zoom>
              <Fade top>
              <p className="h4 py-3">I'm a passionate, multidisciplinary front-end developer, graphic designer, and copywriter. I specialize in visual design, building websites, and UX copywriting. I help build successful brands and create innovative digital experiences.</p>
              <AniLink to="/about" cover hex="#000" duration={1.5}>
                <button className="btn btn-outline-light">Read more</button>
              </AniLink>
              </Fade>
            </div>
            </Fade>
          </Col>
      </Row>

      {/* services */}

      <Row className="row bg-blue bg-taieri min-100 text-light" style={{ backgroundPosition: '0% 10%', backgroundSize: '1300%'}}>

      <Col sm={12} className="align-left p-5">
        <Zoom top cascade>
          <h1 className="site-title">what i do.</h1>
        </Zoom>
      </Col>
      <Col md={4} className="align-left p-5">
        <Fade top>
          <h3 className="text-lowercase font-900">Graphic + <span className="d-table">UI Design</span></h3>
          <p className="h4 py-3">I design UIs and create custom imagery for websites, social media, and print using Photoshop, Canva, Crello, Lunapic and other design tools.</p>
          <AniLink to="/services" cover hex="#000" duration={1.5}>
            <button className="btn btn-outline-light">Read more</button>
          </AniLink>
        </Fade>
      </Col>
      <Col md={4} className="align-left p-5">
        <Fade top>
          <h3 className="text-lowercase font-900">Coding + <span className="d-table">Development</span></h3>
          <p className="h4 py-3">I build websites and single page applications with HTML5, CSS3, SASS, PHP, Javascript, Bootstrap, React, Next.js, Gatsby, Angular, Wordpress and Webflow.</p>
          <AniLink to="/services" cover hex="#000" duration={1.5}>
            <button className="btn btn-outline-light">Read more</button>
          </AniLink>
        </Fade>
      </Col>
      <Col md={4} className="align-left p-5">
        <Fade top>
          <h3 className="text-lowercase font-900">UX Copywriting + <span className="d-table">SEO</span></h3>
          <p className="h4 py-3">I write and edit copy for a wide range of contexts. I have a fast moving, easy-to-read style, and specialize in UX, SEO, and editing for web presentation.</p>
          <AniLink to="/services" cover hex="#000" duration={1.5}>
            <button className="btn btn-outline-light">Read more</button>
          </AniLink>
        </Fade>
      </Col>
    </Row>

    {/* projects */}
      <Row className="min-100 bg-black bg-taieri" style={{backgroundPosition: '60% 10%', backgroundSize: '2000%'}}>
            <Col lg={5} className="d-flex flex-column min-50 text-light p-5">
              <Fade cascade>
                <h1 className="site-title align-center-top">projects.</h1>
                <span className="site-title font-900 mt-auto">+</span>
                </Fade>
                <Fade top>
                  <p className="h4 py-3">I've designed and built websites for a wide range of clients and spaces including software companies, health services providers, real estate agents, musicians and artists, travel companies, designers, and restaurants. Check out some of my work.</p>
                </Fade>
            </Col>
            <Col lg={7} className="align-center-top text-light p-5">
            {/* add project pages to home page */}
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id}>
                <ul className="list-unstyled m-0 p-0">
                  <Fade left cascade>
                  <hr className="w-100 border-light m-0" />
                  <AniLink 
                  cover
                  className="m-0" 
                  to={node.fields.slug}
                  bg="#000"> 
                    <li className="d-flex justify-content-start align-items-center border-light py-3">
                        <h1 className="font-900 m-0">{node.frontmatter.title}</h1>
                        <p className="ml-auto mb-0">{node.frontmatter.tagline}</p>
                    </li>
                    </AniLink>
                    </Fade>
                </ul>
              </div>
              ))}
            </Col>
        </Row>

        {/* contact */}
        <Row className="row bg-orange bg-taieri min-100 text-light"
        style={{ backgroundPosition: '0% 10%', backgroundSize: '1000%'}}>
          <Col className="col-sm-6 align-center-top p-5">
            <Fade cascade>
              <h1 className="site-title">contact info.</h1>
            </Fade>
          </Col>
          <Col className="col-md-6 d-flex flex-column justify-content-end align-items-end p-5">
            <Fade top cascade>
              <h3 className="site-title font-900">email</h3>
              <h3 className="site-title font-900">linkedin</h3>
              <h3 className="site-title font-900">behance</h3>
              <h3 className="site-title font-900">github</h3>
            </Fade>
          </Col>
        </Row>

    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___id, order: ASC}, limit: 6) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            title
            tagline
          }
        }
      }
    }
  } 
`